import React, { useRef, useState } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { useFirebase } from "../../firebase";
import '../../styles.css'; // Adjust the path to point to the actual location of styles.css

export default function Login() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const { auth, googleProvider, facebookProvider } = useFirebase();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await auth.signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
      // Handle successful login, e.g., redirect or update user state
    } catch (error) {
      setError("Failed to log in with email.");
      console.error("Error signing in user: ", error);
    }
    setLoading(false);
  };

  const handleSocialLogin = async (provider) => {
    setLoading(true);
    try {
      await auth.signInWithPopup(provider);
      // Handle successful social login
    } catch (error) {
      setError(`Failed to log in with ${provider.providerId}`);
      console.error(`Error during social login with ${provider.providerId}: `, error);
    }
    setLoading(false);
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <Card.Body>
          <h2 className="login-title">Log In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleEmailLogin}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control className="login-input" type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control className="login-input" type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="login-button" type="submit">
              Log In with Email
            </Button>
          </Form>
          <Button onClick={() => handleSocialLogin(googleProvider)} disabled={loading} className="login-button social-login">
            Log in with Google
          </Button>
          <Button onClick={() => handleSocialLogin(facebookProvider)} disabled={loading} className="login-button social-login">
            Log in with Facebook
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
