import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Signup from './components/Authentication/Signup';
import FacebookSDK from './utils/FacebookSDK'; // Importing the FacebookSDK
import Login from './components/Authentication/Login';
import ArtistProfile from './components/Profiles/ArtistProfile';
import VenueProfile from './components/Profiles/VenueProfile';
import Home from './pages/Home'; 
import Navbar from './components/Navbar';
import ArtistsList from './pages/ArtistsList';
import VenuesList from './pages/VenuesList'; 
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import './styles.css'; 

function App() {
  return (
    <AuthProvider>
      <FacebookSDK /> {/* Including FacebookSDK component */}
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <PrivateRoute path="/artist-profile" component={ArtistProfile} />
          <PrivateRoute path="/venue-profile" component={VenueProfile} />
          <PrivateRoute path="/artists" component={ArtistsList} />
          <PrivateRoute path="/venues" component={VenuesList} />
          {/* Add more routes as needed */}
        </Switch>
        <button className="sign-in-button" type="button">Sign In</button> {/* Sign-in button with the "sign-in-button" class */}
      </Router>
    </AuthProvider>
  );
}

export default App;
