import React from "react";
import { Button } from "react-bootstrap";
import { useFirebase } from "../../firebase";

export default function Logout() {
  const { auth } = useFirebase();

  async function handleLogout() {
    try {
      await auth.signOut();
    } catch (error) {
      alert("Failed to log out. Please try again.");
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Button variant="danger" size="lg" onClick={handleLogout}>
        Log Out
      </Button>
    </div>
  );
}
