import React, { useState } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, signInAnonymously, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useFirebase } from "../../firebase";

import "./signup.css";

function Signup() {
  const { auth, createUserWithEmailAndPassword } = useFirebase();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();

  const handleGoogleSignup = async () => {
    setLoading(true);
    setError("");
    try {
      await signInWithPopup(auth, googleProvider);
      alert("Signed up with Google!");
    } catch (error) {
      console.error("Error during Google signup:", error);
      setError(`Google signup failed: ${error.message}`);
    }
    setLoading(false);
  };

  const handleFacebookSignup = async () => {
    setLoading(true);
    setError("");
    try {
      await signInWithPopup(auth, facebookProvider);
      alert("Signed up with Facebook!");
    } catch (error) {
      console.error("Error during Facebook signup:", error);
      setError(`Facebook signup failed: ${error.message}`);
    }
    setLoading(false);
  };

  const handleAnonymousSignup = async () => {
    setLoading(true);
    setError("");
    try {
      await signInAnonymously(auth);
      alert("Signed up anonymously!");
    } catch (error) {
      console.error("Error during anonymous signup:", error);
      setError(`Anonymous signup failed: ${error.message}`);
    }
    setLoading(false);
  };

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert("Account created successfully!");
    } catch (error) {
      console.error("Error during email signup:", error);
      setError(`Email signup failed: ${error.message}`);
    }
    setLoading(false);
  };

  const handlePhoneSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const appVerifier = new RecaptchaVerifier('sign-up-button', { size: 'invisible' }, auth);
    try {
      const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setConfirmationResult(result);
      alert("OTP has been sent!");
    } catch (error) {
      console.error("Error during phone signup:", error);
      setError(`Phone signup failed: ${error.message}`);
    }
    setLoading(false);
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const userCredential = await confirmationResult.confirm(code);
      alert(`Phone number verified! User is: ${userCredential.user}`);
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setError(`OTP verification failed: ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <div className="signup-container">
      <Card className="signup-card">
        <Card.Body>
          <h2 className="signup-title">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleEmailSignup}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required disabled={loading} />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required disabled={loading} />
            </Form.Group>
            <Button type="submit" disabled={loading}>Sign up with Email</Button>
          </Form>
          <Button onClick={handleGoogleSignup} disabled={loading}>Sign up with Google</Button>
          <Button onClick={handleFacebookSignup} disabled={loading}>Sign up with Facebook</Button>
          <Button onClick={handleAnonymousSignup} disabled={loading}>Sign up Anonymously</Button>
          <Form onSubmit={handlePhoneSignup}>
            <Form.Group id="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required disabled={loading} />
            </Form.Group>
            <Button type="submit" disabled={loading || !phoneNumber}>Send OTP</Button>
          </Form>
          {confirmationResult && (
            <Form onSubmit={verifyOtp}>
              <Form.Group id="otp">
                <Form.Label>OTP</Form.Label>
                <Form.Control type="number" value={code} onChange={(e) => setCode(e.target.value)} required disabled={loading} />
              </Form.Group>
              <Button type="submit" disabled={loading}>Verify OTP</Button>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default Signup;
