import React from 'react';
import { Navbar, Nav } from 'react-bootstrap'; 
import Logout from './Authentication/Logout';
import { useAuth } from '../contexts/AuthContext';

function Navigation() {
  const { currentUser } = useAuth();

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#home">BashMaker</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          {/* Add more links as needed */}
        </Nav>
        {currentUser && <Logout />}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;