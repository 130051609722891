import React, { useState, useEffect } from 'react';
import { db } from '../firebase';

function VenuesList() {
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    const unsubscribe = db.collection("users")
      .where("role", "==", "venue")
      .onSnapshot((snapshot) => {
        const newVenues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));

        setVenues(newVenues);
      });

    return () => unsubscribe(); // Unsubscribe on cleanup
  }, []);

  return (
    <div>
      <h2>Venues</h2>
      {venues.map((venue) => (
        <div key={venue.id}>
          <h3>{venue.name}</h3>
          <p>{venue.description}</p>
        </div>
      ))}
    </div>
  );
}

export default VenuesList;