import React, { createContext, useContext, useState, useEffect } from "react";
import { useFirebase } from "../firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const firebase = useFirebase();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return firebase.createUserWithEmailAndPassword(firebase.auth, email, password);
  }

  function login(email, password) {
    return firebase.signInWithEmailAndPassword(firebase.auth, email, password);
  }

  function logout() {
    return firebase.signOut(firebase.auth);
  }

  function resetPassword(email) {
    return firebase.sendPasswordResetEmail(firebase.auth, email);
  }

  useEffect(() => {
    let unsubscribe = null;
    if (firebase?.auth) {
      unsubscribe = firebase.auth.onAuthStateChanged(user => {
        setCurrentUser(user);
        setLoading(false);
      });
    }    

    return unsubscribe;
  }, [firebase]);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
