/* global FB */
import { useEffect } from 'react';

const FacebookSDK = () => {
  useEffect(() => {
    window.fbAsyncInit = function() {
      FB.init({
        appId      : '644479190932803',
        xfbml      : true,
        version    : 'v19.0'
      });
      FB.AppEvents.logPageView();
    };

    const loadSdkAsync = () => {
      const existingScript = document.getElementById('facebook-jssdk');
      if (!existingScript) {
        const script = document.createElement('script');
        script.id = 'facebook-jssdk';
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        document.body.appendChild(script);
      }
    };

    loadSdkAsync();
  }, []);

  return null;
};

export default FacebookSDK;
