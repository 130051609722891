import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";
import { db } from '../../firebase'; // Import db from firebase.js

export default function VenueProfile() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (currentUser) {
      db.collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUserData(doc.data());
            setLoading(false);
          }
        });
    }
  }, [currentUser]);

  const onSubmit = (data) => {
    if (currentUser) {
      db.collection("users")
        .doc(currentUser.uid)
        .set(data, { merge: true })
        .then(() => {
          alert("Profile updated successfully");
        })
        .catch((error) => {
          console.error("Error updating profile: ", error);
        });
    }
  };

  if (loading && currentUser) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Venue Profile</h2>
      {currentUser ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            Name:
            <input defaultValue={userData ? userData.name : ''} {...register("name")} />
          </label>
          <label>
            Description:
            <textarea defaultValue={userData ? userData.description : ''} {...register("description")} />
          </label>
          {/* Add more fields as needed */}
          <input type="submit" value="Update Profile" />
        </form>
      ) : (
        <p>Please log in to view and update your profile</p>
      )}
    </div>
  );
}
