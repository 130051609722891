import React, { useState, useEffect } from 'react';
import { db } from '../firebase';

function ArtistsList() {
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    const unsubscribe = db.collection("users")
      .where("role", "==", "artist")
      .onSnapshot((snapshot) => {
        const newArtists = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));

        setArtists(newArtists);
      });

    return () => unsubscribe(); // Unsubscribe on cleanup
  }, []);

  return (
    <div>
      <h2>Artists</h2>
      {artists.map((artist) => (
        <div key={artist.id}>
          <h3>{artist.name}</h3>
          <p>{artist.bio}</p>
        </div>
      ))}
    </div>
  );
}

export default ArtistsList;