import React, { createContext, useContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCnUBS-jCOWNuIU9kXsdTJv3hOGWnwUxHs",
  authDomain: "bashmaker-app.firebaseapp.com",
  projectId: "bashmaker-app",
  storageBucket: "bashmaker-app.appspot.com",
  messagingSenderId: "948397130846",
  appId: "1:948397130846:web:bdd89e4bc527914b9e60cf",
  measurementId: "G-HTMSCE3TZE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

export const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const [firebaseInstance, setFirebaseInstance] = useState(null);

  useEffect(() => {
    try {
      let recaptchaVerifier = null;
      if (auth && document.getElementById('sign-in-button')) {
        recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
          'size': 'invisible'
        }, auth);
      }
      setFirebaseInstance({ 
        app, 
        auth, 
        db, 
        storage, 
        googleProvider, 
        facebookProvider, 
        recaptchaVerifier 
      });
    } catch (error) {
      console.error("Firebase initialization error", error);
    }
  }, []);

  return (
    <FirebaseContext.Provider value={firebaseInstance}>
      {!firebaseInstance ? <div>Loading Firebase...</div> : children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return context;
};
