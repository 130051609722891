import React from 'react';
import { Link } from 'react-router-dom';
import "../styles.css";  

function Home() {
    return (
        <div className="home-container">
            <h1>Welcome to BashMaker!</h1>
            <p>Organising any event has never been more fun!</p>

            <div className="navigation-links">
                <Link to="/login" aria-label="Login Page">Login</Link>
                <br/>
                <Link to="/signup" aria-label="Signup Page">Sign Up</Link>
            </div>
        </div>
    );
}

export default Home;
